import React from "react";
import { Helmet } from "react-helmet";

const matomoTagManager = `var _mtm = window._mtm = window._mtm || [];
_mtm.push({
  'mtm.startTime': (new Date().getTime()),
  'event': 'mtm.Start'});
(function() {
var d=document,
  g=d.createElement('script'),
  s=d.getElementsByTagName('script')[0];
  g.async=true;

  g.src='https://webanalytics.vier.ai/js/container_CWXG9HiK.js'; 
  s.parentNode.insertBefore(g,s);
})();
`;

const Tracking = () => {
    return (
        <Helmet>
            <script type="text/plain" data-usercentrics="Matomo (self hosted)">
                {matomoTagManager}
            </script>
        </Helmet>
    )
}

export default Tracking
import React, { useEffect, useState } from "react";
import Tracking from "./tracking";
import MarketingCookie from "./marketingCookie";


const CookieConsentBanner = () => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setIsClient(true);
        }
    }, []);


    return isClient ? (
        <>
            <MarketingCookie/>
            <Tracking />
        </>
    ) : ""
}

CookieConsentBanner.propTypes = {
}

CookieConsentBanner.defaultProps = {
}

export default CookieConsentBanner
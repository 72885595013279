import React from 'react';
import ArticleComponent from './article';
import AuthorComponent from './author';
import CirclesContainer from './circlesContainer';
import CodeComponent from './code';
import CubeNavigation from './cubeNavigation';
import DiagonalBackgroundComponent from './diagonalBackground';
import FlipCardsComponent from './flipCards';
import FloatingContentComponent from './floatingContent';
import FormElements from './formElements';
import GalleryCarousel from './galleryCarousel';
import HeroComponent from './hero';
import LiveStream from './liveStream';
import RichTextComponent from './richText';
import SocialShare from './socialShare';
import TeaserPictureCTA from './teaserPictureCTA';
import TextVideo from './textVideo';

const dynamicComponents = {
  ContentfulComponentSocialShare: SocialShare,
  ContentfulComponentCircleContainer: CirclesContainer,
  ContentfulComponentDiagonalBackgroundContainer: DiagonalBackgroundComponent,
  ContentfulComponentHero: HeroComponent,
  ContentfulComponentFlipCardContainer: FlipCardsComponent,
  ContentfulComponentRichText: RichTextComponent,
  ContentfulComponentGallery: GalleryCarousel,
  ContentfulComponentTextVideoContainer: TextVideo,
  ContentfulComponentFormElements: FormElements,
  ContentfulComponentFloatingContent: FloatingContentComponent,
  ContentfulComponentTeaserPictureCta: TeaserPictureCTA,
  ContentfulComponentCubeNavigation: CubeNavigation,
  ContentfulComponentLiveStream: LiveStream,
  ContentfulComponentCode: CodeComponent,
  ContentfulComponentArticle: ArticleComponent,
  ContentfulComponentAuthor: AuthorComponent
};

var allContentfulPageGlobal = []

export const renderDynamicComponents = (components, lang) => {
  return components.map((component, index) => {
    const DynamicComponent = dynamicComponents[component.internal.type];
    return DynamicComponent ? (
      <DynamicComponent key={index} lang={lang} {...component} />
    ) : null;
  });
};

export const renderPreviewComponents = (components, lang, nodes) => {
  allContentfulPageGlobal = nodes;
  return components.map((component, index) => {
    const contentType = getContentTypeFromSysId(
      component.sys?.contentType?.sys?.id
      );
    const DynamicComponent = dynamicComponents[contentType];
   component = format(component);
    return DynamicComponent ? (
      <DynamicComponent key={index} lang={lang} {...component} />
    ) : null;
  });
};

export const renderArticleComponents = (components, lang) => {
  return components.map((component, index) => {
    const contentType = component.internal?.type || getContentTypeFromSysId(
      component.sys?.contentType?.sys?.id
    );
    const DynamicComponent = dynamicComponents[contentType];
    component = format(component);
    return DynamicComponent ? (
      <DynamicComponent key={index} lang={lang} {...component} />
    ) : null;
  });
};

const normalizeImage = (image) => {
  if (!image.file) return image;

  return {
    title: image.title,
    fluid: {
      src: image.file?.url,
      srcset: image.file?.url,
      ...image.file,
      ...image.file?.details?.image,
      aspectRatio:
        (image.file && image.file.details && image.file.details.image) ? image.file.details.image.width / image.file.details.image.height : 0
    },
    fixed: {
      src: image.file?.url,
      srcset: image.file?.url,
      ...image.file,
      ...image.file?.details?.image
    },
    file: {
      url: image.file?.url,
    }
  };
};

export const format = (entry) => {
  if (!entry) return false;

  const contentTypeId = entry?.sys?.contentType?.sys?.id;

  if (contentTypeId === 'pageGlobal' || contentTypeId === 'page') {
    const page = allContentfulPageGlobal.find((page) => page.slug === entry.fields?.slug);
    entry.fields.id = page?.id;
  }

  if (typeof entry === 'object' && entry.hasOwnProperty('fields')) {
    Object.keys(entry.fields).forEach((key) => {
      if (key === 'localizedPage' || key === 'components')
        return false;
      if (Array.isArray(entry.fields[key]) && key !== 'main') {
        entry.fields[key] = entry.fields[key].map((entry) => format(entry));
      } else {
        entry.fields[key] = format(entry.fields[key]);
      }
    });
    return format(entry.fields);
  }
  if (entry.hasOwnProperty('image')) {
    try {
      entry.image = normalizeImage(entry.image);
    } catch (err) {
      console.error(err);
    }
  }
  if (entry.hasOwnProperty('images')) {
    entry.images.forEach((image, index) => {
      entry.images[index] = normalizeImage(image);
    });
  }
  if (entry.hasOwnProperty('backgroundImage')) {
    try {
      entry.backgroundImage = normalizeImage(entry.backgroundImage);
    } catch (err) {
      console.error(err);
    }
  }
  if (entry.hasOwnProperty("color")) {
    if (typeof entry.color === 'object') {
      entry.colorContainer = entry.color
    }
  }
  if (entry.hasOwnProperty("circles")) {
      entry.circleContainerHeadline = entry.headline
  }
  if (entry.hasOwnProperty("authors")) {
      entry.footerText = entry.text
  }
  if (entry.hasOwnProperty("link")) {
    if (typeof entry.link.target !== 'object') {
      entry.link.url = entry.link.target
    }
  }
  if (entry.hasOwnProperty("subtitle")) {
    entry.textContent = entry.subtitle
  }
  return entry;
};

const getContentTypeFromSysId = (id) => {
  if (!id) {
    return
  }
  const capitalized = id.charAt(0).toUpperCase() + id.slice(1);
  return `Contentful${capitalized}`;
};

